<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import Dialog from "@/components/Dialog";
import {
  getLogList,
  delLog,
  releaseLog,
  copyLog,
} from "@/api/tenant/base/upgradeLogManage";
import { UPGRADELOG_STATUS_TEXT, UPGRADELOG_STATUS } from "@/utils/constant.js";
export default {
  name: "upgradeLogManage",
  components: { TablePage, Dialog },
  data() {
    return {
      dialogOptions: {
        title: "",
        width: 760,
        show: false,
        type: "UpgradeLog",
        formData: null,
      },
      options: {
        loading: true,
        mutiSelect: true,
        selectable: (row) => row.status === UPGRADELOG_STATUS.unpublished,
        listNo: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
          beginBillDate: undefined, //单据开始时间
          endBillDate: undefined, //单据结束时间
          filterTime: undefined, //时间过滤参数
          productName: undefined, //产品名称
          productNo: undefined, //商户名称
        },
        getListApi: getLogList,
        body: {}, // 请求内容
        title: "产品升级发布",
        check: [],
        rowKey: "upgradeId",
        search: [
          {
            label: "日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            label: "产品名称",
            type: "input",
            tip: "请输入产品名称",
            model: "",
            filter: "productName",
            seniorSearch: true,
          },
          {
            label: "产品编号",
            type: "input",
            tip: "请输入产品编号",
            model: "",
            filter: "productNo",
            seniorSearch: true,
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "release",
            label: "发布",
            icon: "el-icon-s-promotion",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh-right",
            type: "",
          },
        ],
        columns: [
          {
            prop: "productNo",
            type: "link",
            click: "update",
            label: "产品编号",
            minWidth: 140,
          },
          {
            prop: "productName",
            label: "产品名称",
            minWidth: 140,
          },
          {
            prop: "upgradeTitle",
            label: "标题",
            minWidth: 180,
          },
          {
            prop: "upgradeProductVer",
            label: "版本号",
            minWidth: 140,
          },
          {
            prop: "upgradeTenantIds",
            label: "所属商户",
            type: "tag",
            tagDefault: "全部商户",
            tagKey: "upgradeTenantInfos",
            tagLabel: "tenantName",
            minWidth: 250,
          },
          {
            prop: "upgradeContent",
            label: "公告内容",
            type: "html",
            minWidth: 180,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 130,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 120,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 130,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 120,
          },
          {
            prop: "status",
            label: "发布状态",
            formatter: (e) => UPGRADELOG_STATUS_TEXT[e],
            minWidth: 100,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        case "update":
          {
            const options = {
              show: true,
              title: "编辑版本信息",
              click: type,
              formData: row,
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "add":
          {
            const options = {
              show: true,
              title: "新增版本信息",
              click: type,
              formData: {},
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "del":
          try {
            await delLog(this.options.check.map((x) => x.upgradeId));
            this.$message.success("删除成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "release":
          try {
            if (this.options.check.length === 1) {
              this.$confirm("确认要发布新版本吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                closeOnClickModal: false,
                showClose: false,
              }).then(async () => {
                await releaseLog(this.options.check[0].upgradeId);
                this.$message.success("发布成功！");
                this.$refs.tablePage.getList();
              });
            } else {
              this.$message.warning("请选择一个版本进行发布！");
            }
          } catch (error) {}
          break;
        case "copy":
          try {
            await copyLog(this.options.check.map((x) => x.upgradeId));
            this.$message.success("复制成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        default:
          break;
      }
    },
  },
};
</script>
